import { render, staticRenderFns } from "./TimeRecs.vue?vue&type=template&id=390ecd1a&scoped=true&"
import script from "./TimeRecs.vue?vue&type=script&lang=js&"
export * from "./TimeRecs.vue?vue&type=script&lang=js&"
import style0 from "./TimeRecs.vue?vue&type=style&index=0&id=390ecd1a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390ecd1a",
  null
  
)

export default component.exports